import { Component } from "@angular/core";
import {
  OrderDetailFragment,
  OrderHistoryEntryComponent,
  SharedModule,
  TimelineDisplayType,
  TimelineHistoryEntry,
} from "@vendure/admin-ui/core";

@Component({
  selector: "order-prescription-acceptance-entry",
  templateUrl: "./order-prescription-acceptance-history-entry.component.html",
  standalone: true,
  imports: [SharedModule],
})
export class OrderPrescriptionAcceptanceHistoryEntryComponent
  implements OrderHistoryEntryComponent
{
  entry: TimelineHistoryEntry;
  order: OrderDetailFragment;

  getDisplayType(entry: TimelineHistoryEntry): TimelineDisplayType {
    return entry.data.accepted ? "success" : "error";
  }

  getName(entry: TimelineHistoryEntry): string {
    return entry.administrator?.firstName + " " + entry.administrator?.lastName;
  }

  isFeatured(entry: TimelineHistoryEntry): boolean {
    return true;
  }

  getIconShape(entry: TimelineHistoryEntry) {
    return entry.data.accepted ? "check-circle" : "exclamation-circle";
  }
}
