<div *ngIf="entry.data.accepted">
  {{
    "leaftec-shop-vendure.order-prescription-acceptance-history-entry.accepted"
      | translate
  }}
</div>
<div *ngIf="!entry.data.accepted">
  {{
    "leaftec-shop-vendure.order-prescription-acceptance-history-entry.rejected"
      | translate
  }}
</div>
