import {
  addNavMenuItem,
  addActionBarItem,
  ActionBarContext,
  registerDashboardWidget,
  registerHistoryEntryComponent,
} from "@vendure/admin-ui/core";
import { RelationBrandInputComponent } from "./components/relation-brand-input/relation-brand-input.component";
import { registerFormInputComponent } from "@vendure/admin-ui/core";
import { RelationOriginCountryInputComponent } from "./components/relation-origin-country-input/relation-origin-country-input.component";
import { RelationStrainInputComponent } from "./components/relation-strain-input/relation-strain-input.component";
import { fileToJson } from "./helpers/file-to-json";
import { BatchCreateProductInput } from "./gql/graphql";
import { BATCH_CREATE_PRODUCTS } from "./graphql/batch.graphql";
import { SEND_ORDER_EMAIL } from "./graphql/order.graphql";
import { firstValueFrom } from "rxjs";
import { Order } from "@vendure/common/lib/generated-types";
import { graphql } from "./gql";
import { OrderPrescriptionAcceptanceHistoryEntryComponent } from "./components/order-prescription-acceptance-history-entry/order-prescription-acceptance-history-entry.component";
import { map } from "rxjs/operators";

export default [
  addNavMenuItem(
    {
      id: "brands",
      label: "Brands",
      routerLink: ["extensions", "leaftec-shop-vendure", "brands"],
      icon: "star",
      requiresPermission: "ReadBrand",
    },
    "catalog"
  ),
  addNavMenuItem(
    {
      id: "origin-countries",
      label: "Origin countries",
      routerLink: ["extensions", "leaftec-shop-vendure", "origin-countries"],
      icon: "flag",
      requiresPermission: "ReadOriginCountry",
    },
    "catalog"
  ),
  addNavMenuItem(
    {
      id: "flavors",
      label: "Flavors",
      routerLink: ["extensions", "leaftec-shop-vendure", "flavors"],
      icon: "helix",
      requiresPermission: "ReadFlavor",
    },
    "catalog"
  ),
  addNavMenuItem(
    {
      id: "strains",
      label: "Strains",
      routerLink: ["extensions", "leaftec-shop-vendure", "strains"],
      icon: "helix",
      requiresPermission: "ReadStrain",
    },
    "catalog"
  ),
  addNavMenuItem(
    {
      id: "shop-product-variants",
      label: "Produkte",
      routerLink: [
        "extensions",
        "leaftec-shop-vendure",
        "shop-product-variants",
      ],
      requiresPermission: "ReadShopProductVariant",
    },
    "catalog"
  ),
  addNavMenuItem(
    {
      id: "my-shop",
      label: "Mein Shop",
      icon: "store",
      routerLink: ["extensions", "leaftec-shop-vendure", "my-shop"],
    },
    "catalog"
  ),
  // overriding default
  addNavMenuItem(
    {
      id: "products",
      label: "Produkte",
      routerLink: ["catalog", "products"],
      icon: "library",
      requiresPermission(userPermissions) {
        return (
          userPermissions.includes("SuperAdmin") ||
          (userPermissions.includes("ReadProducts") &&
            !userPermissions.includes("ReadShopProductVariant"))
        );
      },
    },
    "catalog"
  ),
  // overriding default
  addNavMenuItem(
    {
      id: "assets",
      label: "Assets",
      routerLink: ["catalog", "assets"],
      icon: "image-gallery",
      requiresPermission(userPermissions) {
        return (
          userPermissions.includes("SuperAdmin") ||
          (userPermissions.includes("ReadAssets") &&
            !userPermissions.includes("ReadShopProductVariant"))
        );
      },
    },
    "catalog"
  ),
  // overriding default
  addNavMenuItem(
    {
      id: "sellers",
      label: "Verkäufer",
      routerLink: ["settings", "sellers"],
      icon: "store",
      requiresPermission(userPermissions) {
        return (
          userPermissions.includes("SuperAdmin") ||
          (userPermissions.includes("ReadSeller") &&
            !userPermissions.includes("ReadShopProductVariant"))
        );
      },
    },
    "settings"
  ),
  // overriding default
  addNavMenuItem(
    {
      id: "channels",
      label: "Kanäle",
      routerLink: ["settings", "channels"],
      icon: "layers",
      requiresPermission(userPermissions) {
        return (
          userPermissions.includes("SuperAdmin") ||
          (userPermissions.includes("ReadChannel") &&
            !userPermissions.includes("ReadShopProductVariant"))
        );
      },
    },
    "settings"
  ),
  addActionBarItem({
    id: "batch-create-products",
    label: "Batch create products",
    locationId: "product-list",
    requiresPermission: "SuperAdmin",
    onClick: batchCreateProducts,
  }),
  addActionBarItem({
    id: "send-order-email",
    label: "Zahlungsaufforderung senden",
    locationId: "order-detail",
    onClick: async (event, context) => {
      let clicked = false;

      const entity = await firstValueFrom(context.entity$);

      if (entity?.__typename === "Order" && !clicked) {
        context.dataService
          .mutate(SEND_ORDER_EMAIL, {
            orderId: entity?.id,
          })
          .subscribe((result) => {
            clicked = true;
          });
      }
    },
  }),
  addActionBarItem({
    id: "accept-prescription",
    label: "Rezept akzeptieren",
    locationId: "order-detail",
    requiresPermission: "EditPrescriptionAcceptance",
    icon: "check",
    buttonState(context) {
      console.log("hallo", context);

      return context.entity$.pipe(
        map((entity) => {
          console.log("entity", entity);

          if (entity?.__typename === "Order") {
            const order = entity as Order;
            console.log(order.customFields);

            return {
              visible: !order.customFields.prescriptionAccepted,
              disabled: false,
            };
          }

          return {
            visible: false,
            disabled: true,
          };
        })
      );
    },
    onClick: async (event, context) => {
      const entity = await firstValueFrom(context.entity$);

      const SET_ORDER_PRESCRIPTION_ACCEPTED = graphql(`
        mutation SetOrderPrescriptionAccepted(
          $orderId: ID!
          $accepted: Boolean!
        ) {
          setOrderPrescriptionAccepted(orderId: $orderId, accepted: $accepted) {
            result
          }
        }
      `);

      if (entity?.__typename === "Order") {
        const order = entity as Order;

        context.dataService
          .mutate(SET_ORDER_PRESCRIPTION_ACCEPTED, {
            orderId: order.id as string,
            accepted: true,
          })
          .subscribe((result) => {
            context.notificationService.success("Rezept akzeptiert");
          });
      }
    },
  }),
  addActionBarItem({
    id: "print-label",
    label: "Etikett drucken",
    locationId: "order-detail",
    requiresPermission: "ReadOrder",
    icon: "printer",
    onClick: async (event, context) => {
      const entity = await firstValueFrom(context.entity$);

      console.log(context);

      const GET_ACTIVE_CHANNEL = graphql(`
        query ActiveChannel {
          activeChannel {
            seller {
              customFields {
                logo {
                  preview
                }
              }
            }
          }
        }
      `);

      const { activeChannel } = await firstValueFrom(
        context.dataService.query(GET_ACTIVE_CHANNEL).single$
      );

      const logoUrl = activeChannel?.seller?.customFields?.logo?.preview;

      if (entity?.__typename === "Order") {
        const order = entity as Order;

        // Get current URL for QR code
        const currentUrl = window.location.href;

        // Create the content for printing
        const printContent = `
          <style>
            @media print {
              body { 
                font-family: Arial, sans-serif;
                text-align: center;
                padding: 10px;
                margin: 0;
              }
              .label {
                border: 1px solid black;
                padding: 10px;
                display: inline-block;
                height: 96px;
              }
              .label-content {
                display: flex;
                align-items: start;
                gap: 20px;
                height: 100%;
              }
              .label-right {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: space-between;
                height: 100%;
              }
              #order-code {
                margin: 0;
                font-size: 30px;
              }
              #qrcode {
                margin: 0;
              }
              #logo {
                width: 150px;
                max-height: 100px;
                object-fit: contain;
              }
            }
          </style>
          <div class="label">
            <div class="label-content">
              <div id="qrcode"></div>
              <div class="label-right">
                <h1 id="order-code"><code>${order.code}</code></h1>
                <img id="logo" src="${logoUrl}" alt="Logo" />
              </div>
            </div>
          </div>
          <script src="https://cdn.jsdelivr.net/npm/qrcodejs@1.0.0/qrcode.min.js"></script>
          <script>
            new QRCode(document.getElementById("qrcode"), {
              text: "${currentUrl}",
              width: 96,
              height: 96,
            });
          </script>
        `;

        // Create a hidden iframe for printing
        const iframe = document.createElement("iframe");
        iframe.style.display = "none";
        document.body.appendChild(iframe);

        // Write content to iframe and print
        iframe.contentDocument?.write(printContent);
        iframe.contentDocument?.close();

        // Wait for QR code to generate before printing
        setTimeout(() => {
          iframe.contentWindow?.print();

          // Remove iframe after printing
          setTimeout(() => {
            document.body.removeChild(iframe);
          }, 1000);
        }, 500);
      }
    },
  }),
  registerDashboardWidget("stock-chart", {
    title: "Bestände",
    supportedWidths: [6, 8, 12],
    requiresPermissions: ["ReadShopProductVariant"],
    loadComponent: () =>
      import("./components/stock-widget/stock-chart-widget.component").then(
        (m) => m.StockChartWidgetComponent
      ),
  }),
  registerFormInputComponent(
    "relation-brand-input",
    RelationBrandInputComponent
  ),
  registerFormInputComponent(
    "relation-origin-country-input",
    RelationOriginCountryInputComponent
  ),
  registerFormInputComponent(
    "relation-strain-input",
    RelationStrainInputComponent
  ),
  registerHistoryEntryComponent({
    type: "ORDER_PRESCRIPTION_ACCEPTANCE_MODIFIED",
    component: OrderPrescriptionAcceptanceHistoryEntryComponent,
  }),
];

function batchCreateProducts(event: Event, context: ActionBarContext) {
  const fileInput = document.createElement("input");
  fileInput.style.display = "none";
  fileInput.type = "file";

  // Append the input to the body (or any other element, if needed)
  document.body.appendChild(fileInput);

  // Listen for the file selection
  fileInput.addEventListener("change", async (event: Event) => {
    const file = (event.target as HTMLInputElement)?.files?.[0];

    if (file) {
      const fileContent = await fileToJson<BatchCreateProductInput[]>(file);

      context.dataService
        .mutate(BATCH_CREATE_PRODUCTS, {
          input: fileContent,
        })
        .subscribe((result) => {});
    } else {
      console.log("No file selected.");
    }
  });

  // Trigger the file input click event to prompt file selection
  fileInput.click();
}
